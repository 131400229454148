.toast {
  width: 390px;
}

.toast .bp4-toast-message {
  padding: 17px 0 20px 25px;
}

.toast.bp4-toast.bp4-intent-success {
  background-color: #f0fcf3;
  color: #161616;
}

.toast.bp4-toast.bp4-intent-success .bp4-button {
  background-color: #f0fcf3 !important;
}

.toast.bp4-toast[class*="bp4-intent-"] a {
  color: #215db0;
}

.toast.bp4-toast.bp4-intent-success .bp4-button:last-child > .bp4-icon-cross {
  color: #161616 !important;
}

.toast.bp4-toast.bp4-intent-success .bp4-button:last-child > .bp4-icon-cross > svg {
  width: 12px;
  height: 12px;
}

.toast.bp4-toast.bp4-intent-success .bp4-button:hover {
  background-color: rgba(50, 164, 103, 0.5) !important;
  color: #ffffff !important;
}
.toast.bp4-toast.bp4-intent-success .bp4-button:active {
  background-color: #32a467 !important;
  color: #ffffff !important;
}
.toast.bp4-toast.bp4-intent-success .bp4-button:focus {
  outline-color: rgba(255, 255, 255, 0.5);
}

.toast-error.bp4-toast.bp4-intent-warning {
  background: #ffdee0;
}
.toast-error.bp4-toast.bp4-intent-warning .bp4-button {
  background-color: #ffdee0 !important;
}
