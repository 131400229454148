body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.marker-popover.bp4-tooltip2 .bp4-popover2-content {
  background: #ffffff;
  color: #000000;
}

.marker-popover.bp4-tooltip2 path.bp4-popover2-arrow-fill {
  fill: #ffffff;
}
.error-popover .bp4-popover2-content {
  background: #ffdee0;
  color: #161616;
}

.error-popover.bp4-tooltip2 path.bp4-popover2-arrow-fill {
  fill: #ffdee0;
}
