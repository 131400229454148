.str-chat {
  --str-chat__own-message-bubble-background-color: #14305a;
  --str-chat__own-message-bubble-color: #ffffff;
  --str-chat__message-bubble-background-color: #f2f2f7;
  --str-chat__message-bubble-color: #161616;
  --str-chat__channel-preview-unread-badge-background-color: #32a467;

  font-family: "Roboto", sans-serif !important;
}

.str-chat__channel-header {
  background-color: #e5e5ea;
}

.str-chat__channel-list .str-chat__channel-list-messenger .str-chat__channel-list-messenger__main,
.str-chat__channel-search-result-list {
  padding: 0px 12px;
}

.str-chat__channel-preview,
.str-chat__channel-search .str-chat__channel-search-result-list .str-chat__channel-search-result {
  border-radius: 4px;
  cursor: pointer;
}

.str-chat__message-text {
  font-size: 14px;
  line-height: 1.2;
}

.chat-portal .bp4-overlay {
  width: fit-content;
  height: fit-content;
}

.str-chat__channel-header-info {
  display: none;
}

.str-chat__file-input-container,
.str-chat__message-textarea-emoji-picker,
.str-chat__suggestion-list-container {
  display: none !important;
}

.str-chat__channel-search.str-chat__channel-search--with-results.str-chat__channel-search--inline {
  height: fit-content;
}

.str-chat__message-list-scroll {
  padding-right: 24px !important;
}

.str-chat__message-simple {
  position: relative;
}

.str-chat__message-data .str-chat__avatar-fallback {
  display: none;
}

.read_avatars .str-chat__avatar-fallback {
  margin: 1px 0 2px;
}

.str-chat__message-simple-status-number {
  display: none;
}

.str-chat__channel-preview-end-first-row {
  display: flex;
  justify-content: space-between;
}

.str-chat__channel-preview-unread-badge {
  font-family: Roboto, sans-serif;
  font-size: 12px !important;
}

.str-chat__send-button {
  height: 38px !important;
}
