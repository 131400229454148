.ag-theme-alpine .jobs-panel-grid,
.stops-panel-grid {
  --ag-selected-row-background-color: rgba(64, 106, 255, 0.2);
  --ag-row-hover-color: rgba(0, 56, 255, 0.1);
  --ag-cell-horizontal-padding: 2px !important;

  .ag-side-bar .ag-side-buttons {
    display: none;
  }

  .map-icon-header .ag-header-cell-label {
    justify-content: center;
  }

  .job-searchbar-error .bp4-popover2,
  .job-searchbar .bp4-popover2 .bp4-popover2-content .job-searchbar-warning .bp4-popover2,
  .job-searchbar .bp4-popover2 .bp4-popover2-content {
    border-radius: 4px;
  }

  .job-searchbar-error .bp4-tooltip2 .bp4-popover2-content {
    background-color: #ffdee0;
    color: #161616;
  }

  .job-searchbar-warning .bp4-tooltip2 .bp4-popover2-content {
    background-color: #fce9de;
    color: #161616;
  }

  .job-searchbar-error .bp4-tooltip2 .bp4-popover2-arrow-fill {
    fill: #ffdee0;
  }

  .job-searchbar-warning .bp4-tooltip2 .bp4-popover2-arrow-fill {
    fill: #fce9de;
  }
}

.time-picker-popover {
  transform: translateY(4px) !important;
  border-radius: 4px;
  overflow: hidden;
}

.driver-qualified-popover {
  box-shadow: 0px 0px 5px 0px #00000040;
  margin-top: 8px;
}

.driver-qualified-popover .bp4-popover2-content {
  max-width: 318px;
  padding: 8px;
  background-color: #ffffff;
  color: #161616;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
}

.driver-qualified-popover .bp4-popover2-arrow-fill {
  fill: #ffffff;
}

.message-backdrop {
  background: transparent !important;
}

.hide-row {
  display: none !important;
}

.ag-cell.drag-column .ag-cell-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.ag-cell-wrapper {
  height: 100%;
}
