.MuiTablePagination-displayedRows {
  display: flex;
  align-items: center;
  margin: 0 1px 0 -3px;
}

div.MuiTablePagination-toolbar {
  min-height: 43px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

td.MuiTablePagination-root {
  min-height: 43px;
  max-height: fit-content;
  margin: 0;
  padding: 0;
}
div.MuiToolbar-root {
  min-height: 43px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
}
div.MuiInputBase-root {
  width: 45px;
  margin-left: -6px;
  margin-right: 0;
  padding: 0;
}

div.MuiBox-root {
  margin-left: 0;
}
.MuiSelect-select {
  padding-right: 5px;
}

p.MuiTablePagination-selectLabel {
  font-size: 13px;
  margin-bottom: 0;
}

.MuiSelect-standard {
  padding: 0 22px 0 0 !important;
  margin-right: -4px !important;
}

.create-manifest-portal .bp4-popover2 {
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 4px !important;
  margin-top: -10px;
}

.create-manifest-portal .bp4-popover2-content {
  border-radius: 4px;
  border: 1px solid #e4e7eb;
}

.manifest-card-header-popover {
  box-shadow: 0px 0px 5px 0px #00000040;
  border-radius: 8px;
  font-family: "Roboto", Sans-Serif;
}

.manifest-card-header-popover .bp4-popover2-arrow-fill {
  fill: #ffffff;
  border: none;
}

.manifest-card-header-popover .bp4-popover2-content {
  max-width: 320px;
  color: #161616;
  word-wrap: break-word;
  word-break: break-all;
  background: #ffffff;
  border-radius: 8px;
  font-size: 12px;
  padding: 8px;
  box-shadow: none;
  border: none;
}

.manifest-warning-popover {
  width: 320px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  border: none;
  box-shadow: none;
}

.manifest-warning-popover .bp4-popover2-content {
  background: #ffdee0;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: none;
  border: none;
  color: #161616;
  padding: 8px;
  transform: translate(40px);
}
.manifest-warning-popover .bp4-popover2-arrow-fill {
  fill: #ffdee0 !important;
  border: none;
}

.manifest-card-address-popover {
  box-shadow: 0px 0px 5px 0px #00000040;
  border-radius: 4px;
  min-width: 170px;
}

.manifest-card-address-popover .bp4-popover2-content {
  color: #161616;
  word-wrap: break-word;
  background: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  padding: 12px;
  box-shadow: none;
  border: none;
}

.manifest-card-address-popover .bp4-popover2-arrow-fill {
  fill: #ffffff !important;
  border: none;
}

.manifest-card-service-popover {
  box-shadow: 0px 0px 5px 0px #00000040;
  border-radius: 4px;
}

.manifest-card-service-popover .bp4-popover2-content {
  color: #161616;
  background: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px;
  box-shadow: none;
  border: none;
}

.manifest-card-service-popover .bp4-popover2-arrow-fill {
  fill: #ffffff !important;
  border: none;
}
