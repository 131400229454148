@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/table/lib/css/table.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "@blueprintjs/select/lib/css/blueprint-select.css";

.spaces-resize-handle {
  background-color: #f6f7f9;
  width: 3px !important;
}

.driver-qualification-popover2 {
  max-width: 235px;
  background-color: #ffffff;
}

.ag-tool-panel-wrapper {
  overflow: visible !important;
}

.ag-side-bar .ag-react-container {
  width: 100%;
}

.colorized-indicators-select-popover .bp4-popover2-arrow,
.color-selector-select-popover .bp4-popover2-arrow,
.colorized-indicators-multiselect-popover .bp4-popover2-arrow,
.colorized-indicators-multiselect-popover-content .bp4-popover2-arrow,
.service-type-color-select-popover .bp4-popover2-arrow {
  display: none !important;
}

.colorized-indicators-select-popover .bp4-popover2,
.color-selector-select-popover .bp4-popover2,
.colorized-indicators-multiselect-popover-content .bp4-popover2,
.service-type-color-select-popover .bp4-popover2 {
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 4px !important;
  margin-top: -10px;
}
.colorized-indicators-select-popover .bp4-popover2-content,
.color-selector-select-popover .bp4-popover2-content,
.service-type-color-select-popover .bp4-popover2-content {
  border-radius: 4px;
  border: 1px solid #e4e7eb;
}

.colorized-indicators-target,
.color-selector-target {
  min-width: 140px;
}

.service-type-color-select-popover .bp4-menu {
  max-height: fit-content !important;
}

.service-type-color-select-popover .bp4-popover2-transition-container {
  min-width: fit-content !important;
}

.colorized-indicators-multiselect-popover {
  max-width: 400px;
  border-radius: 4px;
  margin-top: -10px;
}

.colorized-indicators-multiselect-popover .bp4-tag {
  background: #8da4beb2;
  border-radius: 4px;
  font-size: 14px;
  padding: 2px, 6px, 2px, 7px;
}

.colorized-indicators-multiselect-popover .bp4-tag-remove {
  color: #ffffff;
}

.setting-tabs .bp4-tab-indicator-wrapper {
  display: none;
}

.setting-tabs .bp4-tab[aria-selected="true"] {
  background-color: #0038ff1a !important;
}
