.time-quick-filter-select .bp4-popover2-content {
  padding: 0;
}

.time-quick-filter-select .bp4-popover2-content ul li {
  padding: 0 5px;
}

.time-quick-filter-select .bp4-popover2-content ul li:first-of-type {
  padding: 5px 5px 0;
}

.time-quick-filter-select .bp4-popover2-content ul li:last-of-type {
  padding: 0px 5px 5px;
}
