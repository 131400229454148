.assign-jobs-confirmation-dialog .bp4-dialog-header {
  position: relative;

  /* remove border top of header */
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
}

.assign-jobs-confirmation-dialog .bp4-dialog-header .bp4-dialog-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.assign-jobs-confirmation-dialog .bp4-dialog-header .bp4-dialog-close-button .bp4-icon {
  color: #161616;
}
