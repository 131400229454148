.manifestSelectCss {
  max-height: 400px;
  overflow-y: auto;
}

.bp4-multi-select {
  max-width: 80px;
  min-width: 30px;
  overflow: hidden;
}
