.warning-toast.bp4-toast.bp4-intent-warning {
  background-color: #fce9de;
  border-radius: 4px;
  box-shadow: 0px 7px 20px 0px #00000059;
}

.warning-toast .bp4-toast-message {
  padding: 0;
}

.warning-toast .bp4-button-group.bp4-minimal {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
}

.warning-toast.bp4-toast.bp4-intent-warning .bp4-button {
  background: none !important;
  padding: 0;
}
.warning-toast.bp4-toast.bp4-intent-warning .bp4-button:hover {
  background-color: #ff8d23 !important;
}

.warning-toast.bp4-toast.bp4-intent-warning .bp4-button:last-child .bp4-icon-cross {
  color: #161616 !important;
}
