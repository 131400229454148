.content-tooltip.bp4-tooltip2 .bp4-popover2-content {
  background: #ffffff;
  color: #161616;
  width: 330px;
  text-wrap: normal;
  padding: 17px 13px;
  font-size: 12px;
  font-weight: 400;
}

.content-tooltip.bp4-tooltip2 path.bp4-popover2-arrow-fill {
  fill: #ffffff;
}
