.optimization-time-picker .MuiInputBase-root {
  height: 32px;
  width: 100%;
  margin: 0;

  * {
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.optimization-time-picker .MuiIconButton-root .MuiSvgIcon-root {
  transform: scale(0.8);
}

.optimization-time-picker .MuiButtonBase-root:not(.MuiMenuItem-root) {
  padding: 8px;
  margin: 0;
}

.complete-stop-time-picker .MuiInputBase-root {
  width: 170px;
  height: 32px;
  margin: 0;
}

.complete-stop-time-picker .MuiButtonBase-root:not(.MuiMenuItem-root) {
  padding: 8px;
  margin: 0;
}

.time-picker-right {
  width: 92px;
  height: 32px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.optimization-time-picker .MuiPickersLayout-root {
  height: 233px;
  border-radius: 0;
}
.optimization-time-picker .MuiPickersPopper-root {
  position: relative;
}
.MuiPickersLayout-contentWrapper {
  height: 100%;
}
.MuiMultiSectionDigitalClock-root {
  height: 100%;
}
.MuiDialogActions-root {
  display: none !important;
  height: 0px;
  padding: 0px;
}
[aria-label="Select hours"] li {
  color: #394048;
  font-weight: bold;
}

[aria-selected="true"] {
  background: #0038ff33 !important;
  color: #394048 !important;
}

.MuiMenuItem-root:hover {
  background: #0038ff1a !important;
}
.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root::-webkit-scrollbar {
  width: 5px;
}

.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root::-webkit-scrollbar-track {
  background: #fff;
}
.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 5px;
}
